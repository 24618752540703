import Glide from '@glidejs/glide'
import smoothscroll from 'smoothscroll-polyfill'

u(document).on('readystatechange', function() {
  if (document.readyState !== 'complete') return

  smoothscroll.polyfill()

  const dataCategories = JSON.parse(categories),
    dataAllCategories = JSON.parse(all_categories)

  const $htmlBody = u('html, body'),
    $html = u('html'),
    $pageloader = u('.pageloader'),
    $macbookContent = u('.macbook-content'),
    $macbookContentTitle = $macbookContent.find('.macbook-content-title'),
    $macbookContentTags = $macbookContent.find('.macbook-tags'),
    $macbookContentImg = $macbookContent.find('img'),
    $macbookContentInfo = $macbookContent.find('.macbook-content-info'),
    $macbookContentViewDetails = $macbookContent.find('.view-details'),
    $currentShowImg = $macbookContent.find('.macbook-content .current-show-img'),
    $nextShowImg = $macbookContent.find('.macbook-content .next-show-img'),
    $dropdown = u('.dropdown'),
    $dropdownTrigger = $dropdown.find('.dropdown-trigger'),
    $dropdownTriggerButton = $dropdownTrigger.find('.button'),
    $dropdownItem = $dropdown.find('.dropdown-item'),
    $glideContainer = u('.glide-container'),
    $glide = $glideContainer.find('.glide'),
    $glideOverlay = $glideContainer.find('.glide-overlay'),
    $glideInfo = $glideContainer.find('.glide-info'),
    $glideInfoButtons = $glideInfo.find('.buttons'),
    $glideInfoTitle = $glideInfo.find('.glide-info-title'),
    $glideInfoOverview = $glideInfo.find('.glide-info-overview'),
    $glideInfoDescription = $glideInfo.find('.glide-info-description'),
    $glideInfoTags = $glideInfo.find('.glide-info-tags'),
    $glideInfoBackShowcase = $glideInfo.find('.back-showcase'),
    $navbar = u('.navbar'),
    $navbarMenu = $navbar.find('.navbar-menu'),
    $navbarBurger = $navbar.find('.navbar-burger'),
    $navbarItem = $navbar.find('.navbar-item'),
    $navbarBrandItem = $navbar.find('.navbar-brand .navbar-item'),
    $navbarMenuItem = $navbar.find('.navbar-menu .navbar-item'),
    $navbarItemNotShowcase = $navbar.find('.navbar-item:not(.navbar-item-showcase)'),
    $navbarItemShowcase = $navbar.find('.navbar-item-showcase'),
    $navbarItemNotAbout = $navbar.find('.navbar-item:not(.navbar-item-about)'),
    $navbarItemAbout = $navbar.find('.navbar-item-about'),
    $navbarItemNotHome = $navbar.find('.navbar-item:not(.navbar-item-home)'),
    $navbarItemHome = $navbar.find('.navbar-item-home'),
    $recentWorks = u('.recent-works'),
    $recentWorksHeroTitle = $recentWorks.find('.hero-title'),
    $recentWorksHeroSubtitle = $recentWorks.find('.hero-subtitle'),
    $recentWorksHeroDropdown = $recentWorks.find('.hero-dropdown'),
    $recentWorksHeroGlide = $recentWorks.find('.hero-glide'),
    $footer = u('.footer'),
    $footerHeroSocialIcons = $footer.find('.hero-social-icons'),
    $footerHeroQuote = $footer.find('.hero-quote'),
    $footerHeroContent = $footer.find('.hero-content'),
    $about = u('.about'),
    $aboutHeroTitle = $about.find('.hero-title'),
    $aboutContent = $about.find('.content'),
    $home = u('.home'),
    $homeHeroTitle = $home.find('.hero-title'),
    $homeHeroSubtitle = $home.find('.hero-subtitle')
  
  const worksButtons = [
    {
      category_id: 1,
      text: 'View Site',
      icon: 'fa-external-link',
    },
    {
      category_id: 2,
      text: 'View Android',
      icon: 'fa-android',
    },
    {
      category_id: 3,
      text: 'View iOS',
      icon: 'fa-apple',
    },
  ]
  const worksButtonsIcons = worksButtons
    .map(worksButton => worksButton.icon)
    .join(' ')

  let currentGlideCategoryId = -1
  let currentGlideWorkId = -1
  let pageLoaded = false
  let slideLoading = true

  function glideSlider(name) {
    const glide = new Glide(`.glide-${name}`, {
      perView: name == 'Website' ? 4 : 5,
      gap: 15,
      focusAt: 'center',
      keyboard: false,
      breakpoints: {
        767: {
          perView: 3
        },
        599: {
          perView: name == 'Website' ? 2 : 3
        }
      }
    })

    glide.on('mount.after', function() {
      if (glide.selector != '.glide-Website') {
        glide.disable()
      } else {
        u(glide.selector).addClass('show')
        glideAfter()
      }
    })

    glide.on('run', function() {
      slideLoading = true
    })

    glide.on('run.after', function() {
      glideAfter()
    })

    function glideAfter() {
      const $activeSlide = u(glide.selector).find('.glide__slide:not([class*="glide__slide--clone"])')
        .filter((_, i) => i === glide.index)
      const activeImgSrc = $activeSlide
        .find('img')
        .attr('src')
      const activeCategoryId = $activeSlide.find('.slide-container').attr('data-category-id')
      const activeWorkId = $activeSlide.find('.slide-container').attr('data-id')

      if (activeCategoryId == currentGlideCategoryId && activeWorkId == currentGlideWorkId) {
        currentGlideCategoryId = activeCategoryId
        currentGlideWorkId = activeWorkId
        return
      }

      $nextShowImg
        .attr('src', activeImgSrc)
        .removeClass('animate')
        .addClass('show')

      const allCategory = dataAllCategories.find(category => category.id == activeCategoryId)
      const workCategory = allCategory.works_categories.find(work_category => work_category.work_id == activeWorkId)
      const work = workCategory.work
      const tagNames = work.works_tags
        .filter(work_tag => work_tag.category_id == activeCategoryId)
        .map(work_tag => work_tag.tag && work_tag.tag.name || '')

      setTimeout(function() {
        $currentShowImg.attr('src', activeImgSrc)
        $nextShowImg.removeClass('show')

        $currentShowImg.removeClass('hide')
        $macbookContentTitle.html(work.name)
        $macbookContent
          .attr('data-category-id', activeCategoryId)
          .attr('data-id', activeWorkId)
        $macbookContentTags
          .empty()
          .append(name => `<span class="tag is-info">${name}</span>`, tagNames)

        slideLoading = false
      }, 350)

      currentGlideCategoryId = activeCategoryId
      currentGlideWorkId = activeWorkId
    }

    glide.mount()

    $dropdownItem.handle('click', function() {
      const categoryName = u(this).attr('data-name')
      if (`.glide-${categoryName}` == glide.selector) {
        u(`.glide:not(.glide-${categoryName})`).removeClass('show')
        u(`.glide-${categoryName}`).addClass('show')
        
        $dropdownItem.removeClass('is-active')
        u(this).addClass('is-active')
        $dropdown.removeClass('is-active')

        const icon = u(this).find('.icon')
        const text = u(this).find('.is-text')

        $dropdownTrigger.find('.icon.is-left').html(icon.html())
        $dropdownTrigger.find('.is-text').html(text.html())

        $currentShowImg.addClass('hide')
        u(`.macbook-container:not(.macbook-${categoryName})`).removeClass('show block')
        u(`.macbook-${categoryName}`).addClass('block')
        u(`.macbook-${categoryName}`).addClass('show')

        $glideContainer
          .removeClass('Android', 'iOS')
          .addClass(categoryName)

        $macbookContent
          .removeClass('Android', 'iOS')
          .addClass(categoryName)

        glide.enable()
        glideAfter()
      } else {
        glide.disable()
      }
    })
  }

  function events() {
    $macbookContent.on('mouseover', function() {
      if (u(this).hasClass('view-details')) return
      $macbookContentImg.addClass('animate', 'scale')
      $macbookContentInfo.addClass('show')
    })

    $macbookContent.on('mouseleave', function() {
      if (u(this).hasClass('view-details')) return
      $macbookContentImg.removeClass('scale')
      $macbookContentInfo.removeClass('show')
    })

    $macbookContentViewDetails.on('click', function() {
      if (!$glide.hasClass('hide') && !slideLoading) {
        $macbookContent
          .trigger('mouseleave')
          .addClass('view-details')
        $glide.addClass('hide')
        $dropdownTriggerButton.attr('disabled', 'disabled')
        $macbookContentInfo.removeClass('show')

        const activeCategoryId = u('.macbook-content').attr('data-category-id')
        const activeWorkId = u('.macbook-content').attr('data-id')

        const allCategory = dataAllCategories.find(category => category.id == activeCategoryId)
        const workCategory = allCategory.works_categories.find(work_category => work_category.work_id == activeWorkId)
        const work = workCategory.work
        const tagNames = work.works_tags
          .filter(work_tag => work_tag.category_id == activeCategoryId)
          .map(work_tag => work_tag.tag && work_tag.tag.name || '')
        const allCategoryNames = dataAllCategories
          .map(allCategory => `glide-info-${allCategory.name}`)
          .join(' ')

        const workCategories = dataAllCategories
          .map(allCategory => allCategory.works_categories.find(work_category => work_category.work_id == activeWorkId))
          .filter(workCategory => workCategory != undefined && workCategory != null)

        setTimeout(function() {
          $glideOverlay.addClass('move')
        }, 350)

        $glideInfo.removeClass(allCategoryNames)
        $glideInfo.addClass(`glide-info-${allCategory.name}`)

        setTimeout(function() {
          $glideInfo.addClass('show')
          $glideInfoTitle.html(work.name)
          $glideInfoOverview.html(work.overview)
          $glideInfoDescription.html(work.description)
          $glideInfoTags
            .empty()
            .append(name => `<li>${name}</li>`, tagNames)

          const $glideInfoLink = $glideInfo.find('.glide-info-link')
          const cloneGlideInfoLinks = Array.from({ length: worksButtons.length }, () => u($glideInfoLink.clone().last()))
          
          let index = 0
          $glideInfoButtons
            .append(workCategory => {
              const cloneGlideInfoLink = cloneGlideInfoLinks[index]
              const worksButton = worksButtons.find(worksButton => worksButton.category_id == workCategory.category_id)
              cloneGlideInfoLink.find('.work-text').text(worksButton.text)
              cloneGlideInfoLink.find('.work-icon')
                .removeClass(worksButtonsIcons)
                .addClass(worksButton.icon)
              cloneGlideInfoLink.attr('href', workCategory.site_url)
              index++
              return cloneGlideInfoLink
            }, workCategories)
          $glideInfoLink.remove()
        }, 650)
      }
    })

    $glideInfoBackShowcase.on('click', function () {
      if ($glide.hasClass('hide')) {
        const dropdownButtons = document.getElementsByClassName('dropdown-button')
        if (dropdownButtons.length > 0) {
          dropdownButtons[0].removeAttribute('disabled')
        }
        $glideOverlay.removeClass('move')
        $glideInfo.removeClass('show')
        $macbookContent.removeClass('view-details')
        setTimeout(function() {
          $glide.removeClass('hide')
        }, 350)
      } 
    })

    u(document).on('click', function() {
      $dropdown.removeClass('is-active')
    })

    $dropdownTrigger.on('click', function(event) {
      event.stopPropagation()
      
      const buttonIsDisabled = u(this).find('.button').attr('disabled')
      if (buttonIsDisabled) {
        $dropdown.removeClass('is-active')
      } else {
        $dropdown.toggleClass('is-active')
      }
    })

    $navbarBrandItem.handle('click', function(ev) {
      $htmlBody.scroll()
    })

    $navbarMenuItem.handle('click', function(ev) {
      const href = u(this).attr('href')
      u(href).scroll()

      $navbarBurger.removeClass('is-active')
      $navbarMenu.removeClass('is-active')
      $navbar.removeClass('is-active')
    })

    $navbarMenuItem.handle('mouseout', function() {
      this.blur()
    })

    $navbarBurger.handle('click', function() {
      $navbar.addClass('disable-transition')
      $navbarBurger.addClass('disable-transition')

      u(this).toggleClass('is-active')
      $navbarMenu.toggleClass('is-active')
      $navbar.toggleClass('is-active')
    })
  }

  function navbarItemsScroll() {
    const sectionHome = document.getElementById('home')
    const sectionAbout = document.getElementById('about')
    const sectionShowcase = document.getElementById('showcase')
    const sectionFooter = document.getElementById('footer')
    const sectionHomeTop = sectionHome.offsetTop
    const sectionAboutTop = sectionAbout.offsetTop
    const sectionShowcaseTop = sectionShowcase.offsetTop
    const sectionFooterTop = sectionFooter.offsetTop

    const height = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight

    const offsetHeight = 150
    const windowScrollYWithOffset = window.scrollY + offsetHeight

    if (windowScrollYWithOffset >= sectionShowcaseTop) {
      $navbarItemNotShowcase.removeClass('show')
      $navbarItemShowcase.addClass('show')

      if (pageLoaded) {
        $recentWorksHeroTitle.addClass('animate-show')

        setTimeout(function() {
          $recentWorksHeroSubtitle.addClass('animate-show')
        }, 350)

        setTimeout(function() {
          $recentWorksHeroDropdown.addClass('animate-show')
        }, 350 * 2)

        setTimeout(function() {
          $recentWorksHeroGlide.addClass('animate-show')
        }, 350 * 3)

        if (window.scrollY + height >= sectionFooterTop) {
          $footerHeroSocialIcons.addClass('animate-show')

          setTimeout(function() {
            $footerHeroQuote.addClass('animate-show')
          }, 350)

          setTimeout(function() {
            $footerHeroContent.addClass('animate-show')
          }, 350 * 2)
        }
      }
    } else if (windowScrollYWithOffset >= sectionAboutTop) {
      $navbarItemNotAbout.removeClass('show')
      $navbarItemAbout.addClass('show')

      if (pageLoaded) {
        $aboutHeroTitle.addClass('animate-show')

        const contents = $aboutContent.children()
        contents.each(function(content, i) {
          setTimeout(function() {
            u(content).addClass('animate-show')
          }, 350 * (i + 1))
        })
      }
    } else if (windowScrollYWithOffset >= sectionHomeTop) {
      $navbarItemNotHome.removeClass('show')
      $navbarItemHome.addClass('show')

      if (pageLoaded) {
        $homeHeroTitle.addClass('animate-show')

        setTimeout(function() {
          $homeHeroSubtitle.addClass('animate-show')
        }, 350)
      }
    } else {
      $navbarItem.removeClass('show')
    }
  }

  function navbarScroll() {
    $navbar.removeClass('disable-transition')
    $navbarBurger.removeClass('disable-transition')

    if (window.scrollY > 100) {
      $navbar.addClass('scroll')
    } else {
      $navbar.removeClass('scroll')
    }
  }

  function scroll() {
    navbarScroll()
    navbarItemsScroll()
  }

  window.onscroll = scroll
  window.onresize = scroll

  dataCategories.forEach(category => {
    glideSlider(category.name)
  })

  function animate() {
    pageLoaded = true
    navbarItemsScroll()
  }

  setTimeout(function() {
    $pageloader.removeClass('is-active')

    setTimeout(function() {
      $html.addClass('scroll')
    }, 350)

    setTimeout(animate, 650)
  }, 1000)

  events()
  scroll()
})